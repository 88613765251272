import { useAuth0 } from "@auth0/auth0-react";
import { Table } from "react-bootstrap";
import { useContext } from "react";
import AppContext from "../../auth/AppContext";
import { useGetMaintForUser } from "../../hooks/useGetMaintForUser";
import { useState } from "react";
import MaintItemOffCanvas from "./MainItemOffCanvas";
import LoadingInApp from "../../Loading/LoadingInApp";
const MaintTable = () => {
  const { user } = useAuth0();
  const { token } = useContext(AppContext);
  const {
    data: maintList,
    isPending,
    isFetching,
  } = useGetMaintForUser(user, token);
  const [showMaintDetails, setShowMaintDetails] = useState(false);
  const [maintItem, setMaintItem] = useState("");

  const handleRowClick = (maint) => {
    setShowMaintDetails(true);
    setMaintItem(maint);
  };

  return (
    <>
      {isPending || (isFetching && <LoadingInApp />)}
      {maintList?.length === 0 && <h4> No maintenance items found</h4>}
      {maintList?.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Date Completed</th>
              <th>Vehicle</th>
              <th>Maint Item</th>
              <th>Miles</th>
            </tr>
          </thead>
          <tbody>
            {maintList?.map((maint) => (
              <tr key={maint._id} onClick={() => handleRowClick(maint)}>
                <td>{maint.dateCompleted}</td>
                <td>{maint.vehicle}</td>
                <td>{maint.maintItemDesc}</td>
                <td>{maint.miles.toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {showMaintDetails && (
        <MaintItemOffCanvas
          showMaintDetails={showMaintDetails}
          setShowMaintDetails={setShowMaintDetails}
          maintItem={maintItem}
        />
      )}
    </>
  );
};

export default MaintTable;

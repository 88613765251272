import { useQuery } from "@tanstack/react-query";
import Variables from "../Utils/Variables";
import axios from "axios";

const getGasPrices = async (coords, token) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_SERVER_URL}api/gasPrices/${coords.lat}/${coords.lon}`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.error("Error fetching gas stations:", error);
    return [];
  }
};

export const useGetGasPrices = (coords, token) => {
  return useQuery({
    queryKey: [Variables.GAS_PRICES, coords],
    queryFn: () => getGasPrices(coords, token),
    enabled: !!coords && !!token,
    staleTime: 100000,
  });
};

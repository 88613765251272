import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import Variables from "../Utils/Variables";

const getMaintForUser = async (token, user) => {
  const resp = await axios.get(
    `${process.env.REACT_APP_API_SERVER_URL}api/getMaintForUser/${user.sub}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  return resp.data;
};

export const useGetMaintForUser = (user, token) => {
  return useQuery({
    queryKey: [Variables.GET_MAINT_FOR_USER],
    queryFn: () => getMaintForUser(token, user),
    enabled: !!token && !!user,
    staleTime: 100000,
  });
};

import { useQuery } from "@tanstack/react-query";
import { getVehicles } from "../API/VehiclesApi";
import Variables from "../Utils/Variables";

export const useGetVehicles = (user, activeOnly, token) => {
  return useQuery({
    queryKey: [Variables.VEHICLES, user, activeOnly, token],
    queryFn: () => getVehicles(user, activeOnly, token),
    enabled: !!token,
    staleTime: 100000,
  });
};

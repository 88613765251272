import React from "react";
import { Offcanvas, Table } from "react-bootstrap";
import { useContext } from "react";
import AppContext from "../../auth/AppContext";
import { useDeleteMaintItem } from "../../hooks/useDeleteMaintItem";
import { ToastContainer, toast } from "react-toastify";
const MaintItemOffCanvas = ({
  showMaintDetails,
  setShowMaintDetails,
  maintItem,
}) => {
  const { token } = useContext(AppContext);
  const { mutateAsync: removeMaint } = useDeleteMaintItem();

  const handleRemoveMaint = async () => {
    toast.info(`Removing ${maintItem.maintItemDesc}`, {
      theme: "light",
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });
    const id = maintItem._id;

    await removeMaint({ token, id });
    toast.dismiss();

    setShowMaintDetails(false);
    toast.success(`${maintItem.maintItemDesc} removed`, {
      theme: "light",
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });
  };
  return (
    <>
      <Offcanvas
        show={showMaintDetails}
        onHide={() => setShowMaintDetails(false)}
      >
        <Offcanvas.Header closeButton closeVariant={"white"}>
          <Offcanvas.Title>
            Maintenance on{" "}
            {new Date(maintItem.createdOn).toLocaleString("us-en", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Table bordered={false}>
            <tbody>
              <tr>
                <td>
                  <b>Cost:</b>
                </td>
                <td>${maintItem.cost.toFixed(2)}</td>
              </tr>
              <tr>
                <td>
                  <b>Date Completed:</b>
                </td>
                <td>{maintItem.dateCompleted}</td>
              </tr>
              <tr>
                <td>
                  <b>Description:</b>
                </td>
                <td>{maintItem.desc}</td>
              </tr>
              <tr>
                <td>
                  <b>Maintenance Item:</b>
                </td>
                <td>{maintItem.maintItemDesc}</td>
              </tr>
              <tr>
                <td>
                  <b>Miles:</b>
                </td>
                <td>{maintItem.miles.toLocaleString()}</td>
              </tr>
              <tr>
                <td>
                  <b>Vehicle:</b>
                </td>
                <td>{maintItem.vehicle}</td>
              </tr>
            </tbody>
          </Table>
          <div className="pt-2 text-center">
            <button className="btn-custom" onClick={handleRemoveMaint}>
              Remove Maintenance Item
            </button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <ToastContainer />
    </>
  );
};

export default MaintItemOffCanvas;

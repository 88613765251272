import { useEffect } from "react";
import { useGetGasPrices } from "../hooks/useGetGasPrices";
import useBrowserLocation from "../hooks/useBrowserLocation";
import { Container, Row, Col, Card } from "react-bootstrap";
import AppContext from "../auth/AppContext";
import { useContext } from "react";

function GasPrices() {
  const { token } = useContext(AppContext);
  const { coords, getBrowserLocation, isLoading } = useBrowserLocation();
  const {
    data: stations,
    isPending,
    isFetching,
  } = useGetGasPrices(coords, token);

  useEffect(() => {
    if (!coords) getBrowserLocation();
  }, []);

  return (
    <>
      {isPending || isFetching || isLoading ? (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Container className="my-5">
          <Row className="justify-content-center">
            {stations?.map((station, index) => (
              <Col
                key={index}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="mb-4 d-flex justify-content-center"
              >
                <Card style={{ width: "350px" }} className="mb-2 shadow-sm">
                  <Card.Header className="gasStation-header">
                    {station.name}{" "}
                  </Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          station.address
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="gasStation-txt"
                        style={{
                          textDecoration: "none",
                          color: "inherit",
                          cursor: "pointer",
                        }}
                      >
                        <div className="gasStation-txt">{station.address}</div>
                      </a>
                      <div className="gasStation-txt">
                        <strong>Regular:</strong> {station.price}
                      </div>
                      <div className="gasStation-txt">{station.distance}</div>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      )}
    </>
  );
}

export default GasPrices;

import { useQuery } from "@tanstack/react-query";
import Variables from "../Utils/Variables";
import axios from "axios";
import { act } from "react";

const getMaintItemList = async (token, activeOnly = true) => {
  const result = await axios.get(
    `${process.env.REACT_APP_API_SERVER_URL}api/maintItems/getAllItems/${activeOnly}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data;
};

export const useGetMaintItemList = (token, activeOnly) => {
  return useQuery({
    queryKey: [Variables.MAINT_ITEM_LIST],
    queryFn: () => getMaintItemList(token, activeOnly),
    staleTime: Infinity,
    enabled: !!token,
  });
};

import { Form } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../auth/AppContext";
import { useGetVehicles } from "../../hooks/useGetVehicles";

function VehiclesDropDown({ setVehicleId, activeOnly, allOption }) {
  const { user } = useAuth0();
  const { token } = useContext(AppContext);

  // Get vehicles
  const { data: vehicles, isLoading } = useGetVehicles(user, activeOnly, token);

  useEffect(() => {
    // This sets the value of the vehicle if theres only one - so no click action needs to happen
    if (!isLoading && vehicles?.length === 1) {
      setVehicleId({
        vehicleId: vehicles[0]._id,
        vehicle: `${vehicles[0].make} ${vehicles[0].model}`,
      });
    } else {
      // This handles an edge case where theres 1 vehicle and then they add or active another one
      setVehicleId("");
    }
  }, [vehicles]);

  return (
    <div className="">
      <Form.Select
        style={{ maxWidth: "500px" }}
        className={"mt-1 mb-1 "}
        aria-label="Vehicles"
        required
        onChange={(event) => {
          setVehicleId({
            vehicleId: event.target.value,
            vehicle: event.target[event.target.selectedIndex].innerText.trim(),
          });
        }}
      >
        {isLoading ? (
          <option key="loading">Loading...</option>
        ) : vehicles.length === 0 ? (
          <option key="empty">Please add a vehicle</option>
        ) : vehicles.length === 1 ? (
          <option key={vehicles[0]._id} value={vehicles[0]._id}>
            {vehicles[0].make} {vehicles[0].model}
          </option>
        ) : (
          <>
            <option value="0">Select a vehicle</option>
            {allOption && (
              <option key="all" value="all">
                All
              </option>
            )}
            {vehicles.map((vehicle) => {
              return (
                <option key={vehicle._id} value={vehicle._id}>
                  {vehicle.make} {vehicle.model}
                </option>
              );
            })}
          </>
        )}
      </Form.Select>
    </div>
  );
}

export default VehiclesDropDown;

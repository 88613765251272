import { useState } from "react";
import MaintTable from "../Components/Maint/MaintList";
import AddMaint from "../Components/Maint/AddMaint";
import MaintButtons from "../Components/Maint/MaintButtons";
const VehicleMaint = () => {
  const [showAddMaint, setShowAddMaint] = useState(false);

  return (
    <>
      <MaintButtons setShowAddMaint={setShowAddMaint} />
      <MaintTable />
      <AddMaint showAddMaint={showAddMaint} setShowAddMaint={setShowAddMaint} />
    </>
  );
};

export default VehicleMaint;

import { useEffect, useState } from "react";
import { Form, Offcanvas, FormGroup, Container } from "react-bootstrap";
import { useAddVehicleMaint } from "../../hooks/useAddVehicleMaint";
import { useAuth0 } from "@auth0/auth0-react";
import VehiclesDropDown from "../Vehicle/VehiclesDropDown";
import { ToastContainer, toast } from "react-toastify";
import { useGetMaintItemList } from "../../hooks/useGetMaintItemList";
import { useContext } from "react";
import AppContext from "../../auth/AppContext";

const AddMaint = ({ showAddMaint, setShowAddMaint }) => {
  const [vehicleId, setVehicleId] = useState({});
  const { mutateAsync: addVehicleMaint } = useAddVehicleMaint();
  const { user } = useAuth0();
  const [maintItem, setMaintItem] = useState("");
  const [miles, setMiles] = useState("");
  const [desc, setDesc] = useState("");
  const [cost, setCost] = useState("");
  const [dateCompleted, setDateCompleted] = useState(
    new Date().toISOString().split("T")[0]
  );
  const { token } = useContext(AppContext);
  const {
    data: maintItemList,
    isPending,
    isFetching,
  } = useGetMaintItemList(token, true);

  const clearValues = () => {
    setMaintItem("");
    setMiles("");
    setDesc("");
    setCost("");
    setDateCompleted(new Date().toISOString().split("T")[0]);
  };

  const isSubmitEnabled = () => {
    return vehicleId?.vehicleId && maintItem && miles && dateCompleted;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    toast.info("Adding Maintenance...", {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
      theme: "light",
    });
    const vehicleMaint = {
      vehicleId: vehicleId?.vehicleId,
      maintItemId: maintItem,
      miles,
      desc,
      cost,
      dateCompleted,
      user: user.sub,
    };
    try {
      await addVehicleMaint({ vehicleMaint, token });
      toast.dismiss();
      toast.success("Maintenance Added", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 3000,
        theme: "light",
      });
      setShowAddMaint(false);
      clearValues();
    } catch (error) {
      console.error("Error adding maintenance record:", error);
      alert("Failed to add maintenance record");
    }
  };
  return (
    <>
      <Offcanvas show={showAddMaint} onHide={() => setShowAddMaint(false)}>
        <Offcanvas.Header closeButton closeVariant={"white"}>
          <Offcanvas.Title>Add Maintenance</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Container>
            <Form onSubmit={handleSubmit}>
              <Form.Label>Add Maintenance Details</Form.Label>
              <FormGroup>
                <VehiclesDropDown
                  setVehicleId={setVehicleId}
                  activeOnly={true}
                  allOption={false}
                />

                <FormGroup className="mt-2">
                  <Form.Select
                    value={maintItem}
                    onChange={(e) => setMaintItem(e.target.value)}
                  >
                    {isPending || isFetching ? (
                      <option value="">Loading...</option>
                    ) : (
                      <>
                        <option value="">Select Maintenance Item</option>
                        {maintItemList?.map((item) => (
                          <option key={item._id} value={item._id}>
                            {item.maintItemDesc}
                          </option>
                        ))}
                      </>
                    )}
                  </Form.Select>
                </FormGroup>

                <FormGroup className="mt-2">
                  <Form.Control
                    type="number"
                    inputMode="decimal"
                    placeholder="Miles"
                    value={miles}
                    onChange={(e) => setMiles(e.target.value)}
                  />
                </FormGroup>

                <FormGroup className="mt-2">
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </FormGroup>

                <FormGroup className="mt-2">
                  <Form.Control
                    type="number"
                    inputMode="decimal"
                    placeholder="Cost"
                    value={cost}
                    onChange={(e) => setCost(e.target.value)}
                  />
                </FormGroup>

                <FormGroup className="mt-2">
                  <Form.Control
                    type="date"
                    placeholder="Date Completed"
                    value={dateCompleted}
                    onChange={(e) => setDateCompleted(e.target.value)}
                  />
                </FormGroup>

                <div className="text-end mt-2">
                  <button
                    type="submit"
                    className="btn-custom"
                    disabled={!isSubmitEnabled()}
                  >
                    Submit
                  </button>
                </div>
              </FormGroup>
            </Form>
          </Container>
        </Offcanvas.Body>
      </Offcanvas>
      <ToastContainer position="top-center" />
    </>
  );
};
export default AddMaint;

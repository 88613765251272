import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { useQueryClient } from "@tanstack/react-query";
import Variables from "../Utils/Variables";

const deleteMaintItem = async ({ token, id }) => {
  const res = axios.delete(
    `${process.env.REACT_APP_API_SERVER_URL}api/deleteMaintItem/${id}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res;
};

export const useDeleteMaintItem = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ token, id }) => deleteMaintItem({ token, id }),
    onSuccess: () => {
      queryClient.invalidateQueries([Variables.GET_MAINT_FOR_USER]);
    },
  });
};

import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Variables from "../Utils/Variables";

const addVehicleMaint = async ({ vehicleMaint, token }) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_SERVER_URL}api/addVehicleMaint`,
    vehicleMaint,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const useAddVehicleMaint = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ vehicleMaint, token }) =>
      addVehicleMaint({ vehicleMaint, token }),
    onSuccess: () => {
      queryClient.invalidateQueries([Variables.GET_MAINT_FOR_USER]);
    },
  });
};

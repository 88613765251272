import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import ExhistingVehicles from "../Components/Vehicle/ExistingVehicles";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addVehicle } from "../API/VehiclesApi";
import { getMakes, getModels, getYears } from "../API/ModelsApi";
import { useQuery } from "@tanstack/react-query";
import Variables from "../Utils/Variables";
import { useContext, useState } from "react";
import AppContext from "../auth/AppContext";

function AddVehicle() {
  const queryClient = useQueryClient();
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [vin, setVin] = useState("");
  const [desc, setDesc] = useState("");
  const { token } = useContext(AppContext);
  const { user } = useAuth0();

  // Handles adding to database
  const { mutateAsync: newVehicle } = useMutation({
    mutationFn: (vehicle) => addVehicle(vehicle, token),
    onSuccess: () => {
      queryClient.invalidateQueries([Variables.VEHICLES]);
    },
  });

  // Get years
  const { data: years } = useQuery({
    queryKey: ["years", token],
    queryFn: () => getYears(token),
    staleTime: Infinity,
  });

  // Get makes
  const { data: makes, isLoading: isMakesLoading } = useQuery({
    queryKey: ["makes", selectedYear, token],
    queryFn: () => selectedYear > 0 && getMakes(selectedYear, token),
    staleTime: Infinity,
  });

  // Get models
  const { data: models, isLoading: isModelsLoading } = useQuery({
    queryKey: ["models", selectedYear, selectedMake, token],
    queryFn: () =>
      selectedMake.length > 1 && getModels(selectedYear, selectedMake, token),
    staleTime: Infinity,
  });

  // Handle submitting a new vehicle
  const handleSubmit = async (event) => {
    event.preventDefault();
    const vehicle = {
      year: selectedYear,
      make: selectedMake,
      model: selectedModel,
      user: user.sub,
      vin: vin,
      desc: desc,
    };
    toast.info(`Adding ${selectedYear} ${selectedMake} ${selectedModel}`, {
      theme: "light",
      autoClose: false,
      position: toast.POSITION.TOP_CENTER,
    });

    // Add vehicle to database and trigger refresh of existing vehicles
    await newVehicle(vehicle);

    toast.dismiss();

    toast.success(`Added ${selectedYear} ${selectedMake} ${selectedModel}`, {
      theme: "light",
      autoClose: 3000,
      position: toast.POSITION.TOP_CENTER,
    });

    // Reset drop downs
    setSelectedYear("0");
    setSelectedMake("0");
    setSelectedModel("0");
    setVin("");
    setDesc("");
  };

  return (
    <>
      <ExhistingVehicles />

      <h4>Add Vehicle</h4>

      <Form>
        <fieldset>
          <Form.Group className="mb-2 mt-2" controlId="formDropDown">
            <Form.Label>Year*</Form.Label>
            <Form.Select
              onChange={(event) => {
                setSelectedYear(event.target.value);
              }}
              value={selectedYear}
              aria-label="Default year"
              required
            >
              <option value="0"> </option>
              {years &&
                years.map((year) => {
                  return (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  );
                })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2" controlId="formDropDown">
            <Form.Label>Make*</Form.Label>
            <Form.Select
              aria-label="Default make"
              onChange={(event) => {
                setSelectedMake(event.target.value);
              }}
              disabled={selectedYear.length > 1 ? "" : "disabled"}
              required
            >
              <option value="0"> </option>
              {isMakesLoading ? (
                <option value="loading">Loading...</option>
              ) : (
                makes &&
                makes.map((make) => {
                  return (
                    <option key={make} value={make}>
                      {make}
                    </option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formDropDown">
            <Form.Label>Model*</Form.Label>
            <Form.Select
              aria-label="Default model"
              onChange={(event) => {
                setSelectedModel(event.target.value);
              }}
              disabled={selectedMake.length > 1 ? "" : "disabled"}
              required
            >
              <option value="0"> </option>
              {isModelsLoading ? (
                <option value="loading">Loading...</option>
              ) : (
                models &&
                models.map(({ model }) => {
                  return (
                    <option key={model} value={model}>
                      {model}
                    </option>
                  );
                })
              )}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>VIN</Form.Label>
            <Form.Control
              type="text"
              value={vin}
              onChange={(event) => {
                setVin(event.target.value);
              }}
            />
          </Form.Group>
          <Form.Group
            className="mb-2 mt-3"
            controlId="exampleForm.ControlTextarea1"
          >
            <Form.Label>Description</Form.Label>
            <Form.Control
              className="mb-3"
              value={desc}
              as="textarea"
              rows={4}
              onChange={(event) => {
                setDesc(event.target.value);
              }}
            />
          </Form.Group>
        </fieldset>
        <div className="text-end pt-2">
          <button
            className="btn-custom"
            onClick={handleSubmit}
            disabled={
              selectedModel.length >= 1 && selectedMake.length > 1
                ? ""
                : "disabled"
            }
          >
            Add
          </button>
        </div>
      </Form>

      <ToastContainer />
    </>
  );
}

export default AddVehicle;

import { Container, Navbar } from "react-bootstrap";
import VehiclesDropDown from "../Vehicle/VehiclesDropDown";

function MaintButtons({ setShowAddMaint, setVehicle }) {
  return (
    <Container className="p-1 mb-3">
      <div className="d-flex justify-content-end">
        <button onClick={() => setShowAddMaint(true)} className="btn-custom">
          Add Maintenance
        </button>
      </div>
    </Container>
  );
}

export default MaintButtons;
